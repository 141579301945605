<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="inventory-orders"
              :show-labels="true"
              :filters="filteringPanel.filters"
              :loaded="filteringPanel.loaded"
              @async-search="onAsyncSearch"
              @search="getData"
              @change="filterData"
              :load-dictionaries="loadDictionaries"
              @dict-loaded="onFilteringPanelLoad"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>

        <b-row v-show="dataTable.visible">
          <b-col>
            <orders-table ref="orders" />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";

import OrdersTable from "./OrdersTable";

export default {
  name: "Orders",
  components: {
    FilteringPanel,
    OrdersTable
  },
  data: function() {
    return {
      filteringPanel: {
        selected: {},
        loaded: false,
        filters: [
          {
            type: "daterange",
            dataType: "datetime",
            title: "Order Date",
            tooltip: "Order period",
            defaultRange: "Last 12 months",
            name: "inventory_order_period"
          }
        ]
      },
      dataTable: {
        visible: true
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onAsyncSearch(payload) {
      if (payload.filterName === "account") {
        let accountsFilter = this.filteringPanel.filters.find(
          f => f.name === "account"
        );

        accountsFilter.loading = true;

        this.$api
          .post("dictionaries/accounts", {
            query: payload.query,
            starts_with: payload.startsWith
          })
          .then(response => {
            accountsFilter.loading = false;

            accountsFilter.options = response.map(u => ({
              id: u.id,
              label: u.name
            }));
          });
      }
    },
    async loadDictionaries() {
      let self = this;

      const users = async () => {};

      users().then(() => {
        self.filteringPanel.loaded = true;
      });
    },
    onFilteringPanelStateChange() {
      this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder
        .visible;
    },
    onFilteringPanelLoad() {
      this.getData();
    },
    onCustomFilterSelected() {
      this.getData();
    },
    filterData: function(e) {
      this.filteringPanel.selected = e;
    },
    getData() {
      let fp = this.$refs.filteringPanel;

      if (!fp.isSearchAllowed()) return;

      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      let f = fp.selected;

      let payload = {
        inventory_order_period: f.inventory_order_period
      };

      if (fp.panelState.isCustomFiltersBar) {
        payload.expression = JSON.stringify(
          fp.prepareExpressions(f["custom-filter"])
        );
      }

      this.$refs["orders"].getData(payload);
    },
    dropdownFilter(_name) {
      return this.filteringPanel.filters.find(n => n.title === _name);
    },
    onOrdersLoad() {}
  },
  watch: {}
};
</script>

<style></style>
